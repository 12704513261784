@import "../../../styles/base/abstracts";

.hbs-section-header {
  @include grid-parent($offset: true);
}

.hbs-section-header .hbs-section-header-inner {
  align-items: flex-end;
  display: grid;
  width: 100%;

  .hbs-section-header__hgroup {
    .hbs-section-header__title,
    .hbs-section-header__subtitle {
      @include grid-child;
      @include h2($visually-align: true);
      grid-column: 1;
      margin: 0;
      max-width: 13em;

      @include mq($bp-desktop-lg) {
        margin-right: auto;
      }
    }

    .hbs-section-header__subtitle {
      color: $c-text-lighter;
    }

    &.hbs-section-header__hgroup--long {
      .hbs-section-header__title,
      .hbs-section-header__subtitle {
        @include h3;
        max-width: none;
      }
    }
  }

  @include mq($bp-desktop-lg) {
    grid-template-columns: 10fr 6fr;
  }

  @include mq($bp-desktop-xl) {
    grid-template-columns: 11fr 5fr;
  }

  .hbs-section-header__dek {
    @include grid-child;
    @include baseline-text;
    margin-top: $spacing-md;
    max-width: 28em;

    .hbs-cta-link {
      display: flex;
      margin-top: $spacing-sm;
      width: fit-content;
    }

    @include mq($bp-desktop-lg) {
      @include margin(0);
      grid-column: 2;

      &.hbs-section-header__dek--no-desc {
        margin-left: auto;

        .hbs-cta-link {
          margin-left: auto;
        }
      }
    }
  }
}
